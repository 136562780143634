import {BasicObject, SafeAny} from '@types';
import config from '@/utils/env.config';
import theme from '@style';
import {View} from 'react-native';
import React, {useEffect, useState} from 'react';
import globalStore from '@/services/global.state';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import Spin from '@basicComponents/spin';
import {goTo, debounce, toSports} from '@utils';
import {useWebView} from '../hooks/webview.hooks';
import {MagicBoxType, order} from '@/utils/magic-box';
import {getGameURL} from '@/pages/home/home.service';
import {toGame, toAllCasinoGame} from '../game-navigate';
import {postReport, TReportType, TSourceType} from '@/services/global.service';

const gameMap: {
  [key: string]: string;
} = {
  color: 'Color',
  dice: 'Dice',
  '3Digit': 'Digit',
  quick3d: 'Digit',
  scratch: 'Scratch',
  matka: 'GameSatta',
  kerala: 'GameWebView',
  quickStateLottery: 'MixLottery',
  stateLottery: 'MixLottery',
};

type MessageBase = {
  message?: string;
};

interface MessageSend extends MessageBase {
  gameType: MagicBoxType;
  rawData: SafeAny;
  list: SafeAny[];
  issNo: string;
  currentUserID: string;
}

enum EventType {
  PLAY = 'play',
  FOLLOWED = 'followed',
  LOGIN = 'login',
  SUCCESS = 'success',
  FAIL = 'fail',
  BACK = 'back',
  USER_EDITOR = 'userEditor',
  START_GAME = 'startGame',
}

export type MessageDataPayload = {
  event: EventType;
  data: MessageSend;
};

interface IDataFromChat {
  source: string | 'chat-room';
  payload: MessageDataPayload;
}

const DetailWebView = (props: SafeAny) => {
  const {route} = props;
  const {
    path,
    originUrl,
    header,
    headerTitle,
    hideAmount,
    hideServer = true,
    serverRight = false,
  } = route.params;
  const urlPrefix = `${config.reactH5Url}${path}`;
  const [title, setTitle] = useState<string>(headerTitle);
  const [pageLoading, setPageLoading] = React.useState(true);

  const getSportURL = debounce((gametype: string) => {
    getGameURL(gametype)
      .then((url: string) => {
        toSports(gametype.split('_')[0].toUpperCase() + ' Sports', url);
      })
      .catch(e => console.error('error', e));
  });

  const playNow = (fromData: SafeAny) => {
    const {gameType, rawData, gameName} = fromData;
    const gameCode = gameMap[gameType];
    localStorage.setItem('PlayNow', `im-${gameCode}`);
    const jumpParams = {} as SafeAny;
    switch (gameType) {
      case 'quick3d':
      case '3Digit': {
        jumpParams.id = rawData?.pickThreeId;
        jumpParams.pickName = gameName;
        jumpParams.pickGameType = gameType === 'quick3d' ? 'quick' : 'normal';
        // jumpParams.cycle = item.cycle;
        break;
      }
      case 'matka': {
        jumpParams.type = 'kerala';
        jumpParams.lotteryName = rawData?.lotteryName;
        break;
      }
      case 'kerala': {
        const rawData2Str = rawData || {};
        jumpParams.id = rawData?.matkaId;
        jumpParams.params = `data=${JSON.stringify(rawData2Str)}`;
        break;
      }
      case 'quickStateLottery':
      case 'stateLottery': {
        jumpParams.lotteryID = rawData?.lotteryID;
        jumpParams.isQuick = gameType === 'quickStateLottery' ? '1' : '0';
        break;
      }
    }
    if (gameType === 'scratch' && window) {
      const curLink = window.location.href.split('?')[0];
      const jumpLink = curLink.replace('webview', 'index/scratch');
      window.location.replace(jumpLink);
    } else if (['quickRace', 'casino', 'live'].includes(gameType)) {
      toGame({
        source: rawData?.source,
        name: rawData?.gameName,
        gameUrl: rawData?.gameUrl,
        id: rawData?.gameId,
        tripartiteUniqueness: rawData?.tripartiteUniqueness,
      });
    } else if (gameType === 'sport') {
      getSportURL(
        (rawData?.source || '').toLowerCase().split(' ')[0] + '_sport',
      );
    } else {
      goTo(gameCode, jumpParams);
    }
  };

  const handleMessage = (
    data: string | BasicObject | IDataFromChat,
    postMessage?: Function,
  ) => {
    if (data === 'pageLoading:show') {
      setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (typeof data === 'string' && data.startsWith?.('title:')) {
      // 表示更改标题
      setTitle(data.substring('title:'.length));
      return;
    }
    if (typeof data === 'string' && data.startsWith?.('scratch:')) {
      goTo('Scratch', {
        path: data.substring('scratch:'.length),
      });
    }
    if (typeof data === 'object' && data.source === 'chat-room') {
      localStorage.setItem('from', 'im');
      const {event, data: fromData} = data.payload || {};
      const {gameType, userInviteCode} = fromData || {};
      if (userInviteCode) {
        localStorage.setItem('invitationCode', userInviteCode);
      }
      if (fromData.shareId) {
        localStorage.setItem('shareUserId', fromData.shareId);
      }
      switch (event) {
        case EventType.BACK: {
          const curLink = window.location.href.split('?')[0];
          const jumpLink = curLink.replace('webview', 'index/home');
          window.location.replace(jumpLink);
          break;
        }
        case EventType.LOGIN:
          const curLink = window.location.href.split('?')[0];
          const jumpLink = curLink.replace('webview', 'login?fromPage=chat');
          window.location.replace(jumpLink);
          break;
        case EventType.PLAY: {
          playNow(fromData);
          break;
        }
        case EventType.FOLLOWED: {
          order(fromData.rawData, gameType)
            .then(() => {
              const user = localStorage.getItem('user') || '{}';
              const userId = JSON.parse(user).userId;
              postReport({
                reportType: TReportType.BETS,
                gameName: fromData?.gameName,
                gameType,
                userId,
                inviteCode: fromData?.userInviteCode,
                gameId: fromData?.gameId,
                issueNo: fromData?.issNo,
                sourceType: TSourceType.IM,
                shareUserId: fromData?.rawData?.userId || fromData?.shareId,
              });
              postMessage &&
                postMessage({
                  source: 'chat-room',
                  payload: {
                    event: EventType.SUCCESS,
                    data: {
                      message: 'success!',
                    },
                  },
                });
            })
            .catch(() => {
              postMessage &&
                postMessage({
                  source: 'chat-room',
                  payload: {
                    event: EventType.FAIL,
                    data: {
                      message: 'failed!',
                    },
                  },
                });
            });
          break;
        }
        case EventType.USER_EDITOR: {
          const curLink = window.location.href.split('?')[0];
          const jumpLink = curLink.replace('webview', 'profile');
          window.location.replace(jumpLink);
          break;
        }
        case EventType.START_GAME: {
          toAllCasinoGame(fromData?.gameId);
        }
      }
    }
  };
  useEffect(() => {
    // 令webview不因为未登录导致强制跳转登录（不通过配置backPage）
    if (globalStore.token) {
      globalStore.updateAmount.next({});
    }
    let intv: NodeJS.Timeout | null = null;
    if (
      !(hideAmount && hideAmount !== 'false') &&
      headerTitle?.toLowerCase().includes('betby')
    ) {
      intv = setInterval(() => {
        globalStore.updateAmount.next({});
      }, 20000);
    }
    return () => {
      if (intv != null) {
        clearInterval(intv);
        intv = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTitle(headerTitle);
  }, [headerTitle]);

  // const handleGoBack = () => {
  //   ref.current?.goBack();
  // };
  const {render, goBack} = useWebView({
    originUrl,
    urlPrefix,
    onMessage: handleMessage,
    onLoadEnd: () => {
      setPageLoading(false);
    },
  });

  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      {header && (
        <DetailNavTitle
          title={title}
          hideServer={hideServer}
          serverRight={serverRight}
          hideAmount={hideAmount && hideAmount !== 'false'}
          onBack={() => {
            localStorage.removeItem('webviewRouteStatus');
            goBack();
          }}
        />
      )}
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        {render}
      </Spin>
    </View>
  );
};

export default DetailWebView;
